@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

@layer base {
  html {
    @apply bg-white;
  }

  body {
    @apply min-h-screen bg-white;
  }

  #root {
    @apply min-h-screen bg-white;
  }

  html,
  body,
  #root,
  .app,
  .content {
    height: 100%;
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
  }

  .app {
    display: flex;
    position: relative;
  }

  /* Smooth header transition */
  nav {
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease, border-color 0.3s ease;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .gradient-text {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600 relative z-[15];
    -webkit-background-clip: text;
    background-clip: text;
  }
  
  .glass-effect {
    @apply bg-white backdrop-blur-lg border border-white/20 relative z-[5];
  }
  
  .premium-shadow {
    @apply shadow-[0_8px_32px_-8px_rgba(0,0,0,0.2)] hover:shadow-[0_16px_48px_-12px_rgba(0,0,0,0.25)] transition-shadow duration-300;
  }

  .feature-card {
    @apply bg-white p-8 rounded-2xl premium-shadow transition-all duration-500 hover:scale-105 hover:bg-gradient-to-br hover:from-white hover:to-purple-50 relative z-[5];
  }

  .feature-icon-container {
    @apply w-12 h-12 bg-gradient-to-br from-purple-600 to-blue-600 rounded-xl flex items-center justify-center mb-4 transition-transform duration-500 group-hover:scale-110 relative z-[6];
  }

  .feature-icon-container::after {
    content: '';
    @apply absolute inset-0 rounded-xl bg-gradient-to-br from-purple-600 to-blue-600 opacity-0 blur-xl transition-opacity duration-500 group-hover:opacity-20;
  }

  .feature-title {
    @apply text-xl font-bold text-gray-900 mb-2 transition-colors duration-300 group-hover:text-purple-600;
  }

  .feature-description {
    @apply text-gray-600 transition-colors duration-300 group-hover:text-gray-700;
  }

  /* Improved testimonial scroll */
  .testimonial-scroll {
    animation: scroll 90s linear infinite;
    @apply relative z-[15];
    will-change: transform;
    transform: translateZ(0);
  }

  .testimonial-card {
    @apply bg-white p-8 rounded-2xl premium-shadow flex-shrink-0 w-[400px] transition-all duration-500;
    position: relative;
    z-index: 20;
  }

  .testimonial-card:hover {
    @apply scale-105 bg-gradient-to-br from-white to-purple-50;
    animation-play-state: paused;
    z-index: 30;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0) translateZ(0);
    }
    100% {
      transform: translateX(calc(-400px * 6 - 2rem * 6)) translateZ(0);
    }
  }

  .quote-mark {
    @apply absolute text-6xl leading-none text-purple-200 opacity-50;
    z-index: 21;
  }

  .avatar-ring {
    @apply absolute inset-0 rounded-full border-2 border-purple-600/20 scale-110 transition-transform duration-300;
  }
}