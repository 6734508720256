/* src/styles/pdf.css v1.0.3 */

@media print {
  @page {
    size: A4;
    margin: 20mm;
  }

  body {
    background-color: white !important;
    color: #1a1a1a !important;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    color-adjust: exact !important;
    forced-color-adjust: none !important;
  }

  /* Force Light Mode Overrides */
  [class*="darkMode"],
  [class*="dark-mode"],
  [data-theme="dark"] {
    background-color: white !important;
    color: #1a1a1a !important;
  }
}

.pdf-export-mode {
  /* Force Light Mode */
  background-color: white !important;
  color: #1a1a1a !important;

  /* Global Styles */
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    color-adjust: exact !important;
    forced-color-adjust: none !important;
  }

  /* Force Light Mode Overrides */
  &.force-light-mode {
    [class*="darkMode"],
    [class*="dark-mode"],
    [data-theme="dark"] {
      background-color: white !important;
      color: #1a1a1a !important;
    }

    * {
      color: #1a1a1a !important;
    }
  }

  /* Container Styles */
  #forecast-dashboard,
  #healthcheck-dashboard {
    background-color: white !important;
    color: #1a1a1a !important;
    padding: 20px !important;
    max-width: 100% !important;
  }

  /* Typography Improvements */
  .MuiTypography-h4 {
    font-size: 24px !important;
    color: #1a1a1a !important;
    margin-bottom: 16px !important;
  }

  .MuiTypography-h6 {
    font-size: 18px !important;
    color: #1a1a1a !important;
  }

  .MuiTypography-body1,
  .MuiTypography-body2 {
    color: #2c2c2c !important;
  }

  /* Card Styling */
  .MuiCard-root,
  .MuiPaper-root {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    background-color: white !important;
    border: 1px solid #e0e0e0 !important;
    box-shadow: none !important;
    margin-bottom: 16px !important;
  }

  /* Chart Improvements */
  .recharts-wrapper {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    margin: 16px 0 !important;
  }

  .recharts-text {
    fill: #2c2c2c !important;
  }

  .recharts-cartesian-grid-horizontal line,
  .recharts-cartesian-grid-vertical line {
    stroke: #e0e0e0 !important;
  }

  /* Alert Styling */
  .MuiAlert-root {
    background-color: #f5f5f5 !important;
    border: 1px solid #e0e0e0 !important;
    color: #2c2c2c !important;
  }

  .MuiAlert-icon {
    color: #36b5ad !important;
  }

  /* Grid Layout */
  .MuiGrid-container {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    margin-bottom: 24px !important;
  }

  /* Icons */
  .MuiSvgIcon-root {
    color: #36b5ad !important;
  }

  /* Hide Elements */
  .MuiButton-root[aria-label="Export PDF"],
  .no-print {
    display: none !important;
  }

  /* CTA Section */
  .MuiPaper-root[style*="text-align: center"] {
    background-color: #f8f9fa !important;
    border: 1px solid #e0e0e0 !important;
    padding: 24px !important;
  }

  /* Table Styling */
  .MuiTableCell-root {
    border-color: #e0e0e0 !important;
    color: #2c2c2c !important;
  }

  /* Status Colors */
  .success-text {
    color: #2e7d32 !important;
  }

  .warning-text {
    color: #ed6c02 !important;
  }

  .error-text {
    color: #d32f2f !important;
  }

  /* Spacing and Layout */
  .MuiBox-root {
    margin-bottom: 16px !important;
  }

  /* Remove Hover Effects */
  *:hover {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}